.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.centralized-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.centralized-div {
  width: 800px;
  height: 600px;
  background-color: white;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  text-align: center;
  padding: 20px;
  z-index: 1;
}

body {
  font-family: 'Arial', sans-serif;
  font-size: 14px;
  margin: 0;
  padding: 0;
}

nav {
  background-color: #333;
  color: #fff;
  padding: 10px;
  position: sticky;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

li {
  margin-right: 20px;
}

.menu-button {
    font-size: 16px;
    background-color: #333;
    color: #fff;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
}

.submenu-button {
  font-size: 16px;
  background-color: #555;
  color: #fff;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
}

button:disabled {
  background-color: gray;
  color: white;
  cursor: not-allowed;
  opacity: 1;
}

button:hover {
  background-color: #555;
}

button.active {
  background-color: #555;
}

h2 {
  color: #333;
}

ul.submenu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #555;
  margin-top: -5px;
  margin-left: 15px;
  z-index: 1000;
}

ul.submenu li {
  margin: 0;
}

.submenu-button:hover {
  background-color: #777;
}

button {
  background-color: #567ebb;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0px 2px 0px 2px;
}


.finish-button, .remove-button {
  background-color: #ebae56;
}

.close-button {
  background-color: #606d80;
  padding: 1px 6px 2px 6px;
}

.finish-button:hover, .remove-button:hover {
  background-color: #c0392b; /* Cor mais escura quando o mouse passa por cima */
}

select, input[type="text"], input[type="password"], input[type="number"], input[type="file"], textarea {
  padding: 10px;
  margin: 0px 2px 0px 2px;
  box-sizing: border-box;
  border: 1px solid #3498db;
  border-radius: 5px;
}

img {
  margin: 0px 2px 0px 2px;
  box-sizing: border-box;
  border-radius: 5px;
}

input[type="text"]:focus, input[type="password"]:focus, textarea:focus {
  margin: 0px 2px 0px 2px;
  outline: none;
  border: 1px solid #2980b9;
}

table {
  margin-top: 5px;
  border: 1px solid #567ebb;
  border-radius: 10px;
  border-collapse: collapse;
  overflow-y: auto;
  overflow-x: auto;
  display: block;
}

th, td {
  padding: 2px 2px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  min-height: 41px;
}

th {
  position: sticky;
  top: 0;
  background-color: #f2f2f2;

}


tr {
  transition: background-color 0.5s;
  background-color: transparent;
  min-height: 41px;
}

tr:hover {
  background-color: #f0f0f0;
}

.search-button {
  background-color: #339966;
  padding: 8px 10px;
  margin: 0px 2px 0px 2px;
}

.whatsapp-button {
  background-color: transparent;
  padding: 2px 2px;
  margin: 0px;
}

.clean-button {
  background-color: #606d80;
}

.add-button {
  background-color: #ffffff;
  padding: 8px 10px;
  margin: 0px 2px 0px 2px;
}

.back-button {
  background-color: #b0b0b0;
}


.total-label {
  color: #2b4c7e;
  font-size: 20px;
}

.header-label {
  color: #000000;
  font-size: 25px;
  font-weight: bold;
}

.sub-header-label {
  color: #000000;
  font-size: 16px;
  font-weight: bold;
}

.centralized-div1 {
  width: 320px;
  left: 50%;
  margin: 20px auto;
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 20px;
}

:root {
  --error-color: #f28c8c;
  --warning-color: #f5e386;
  --normal-green-color: #a8e6cf;
  --normal-color: #fff;
}

.datepicker {
  width: 110px;
}

.dashboard-wrapper {
  height: 100%;
  padding: 10px;
  width: 768px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 768px) {
  .dashboard-wrapper {
    width: 95%;
    height: 50%;
  }
}