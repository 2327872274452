.date-enabled {
    background-color: rgba(0, 128, 0, 0.66) !important;
}

.date-disabled {
    background-color: rgb(190, 190, 190) !important;
    cursor: not-allowed !important;
}

.date-disabled-rented {
    background-color: rgb(206, 86, 86) !important;
    cursor: not-allowed !important;
}

.date-return {
    background-color: rgb(210, 186, 65) !important;
    cursor: not-allowed !important;
}

.date-unavailable-to-deliver {
    background-color: rgba(76, 215, 76, 0.66) !important;
    cursor: not-allowed !important;
}

.react-calendar {
    width: 100%;
    max-width: 100%;
    background: white;
    border: 1px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    padding: 2px;
    margin-top: 5px;
}

.react-calendar__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;

    gap: 0px;
}

.react-calendar__tile {
    padding: 20px 0;
    text-align: center;
    line-height: 1.2em;
    font-weight: bold;
    cursor: pointer;
    max-width: calc(100% / 7 - 3px);
    margin: 2px;
}

.react-calendar__year-view__months__month {
    background: rgba(41, 111, 124, 0.44);
}

.react-calendar__decade-view__years__year{
    background: rgba(41, 111, 124, 0.44);
}


.react-calendar__tile--now {
    background: #8dd4e6;
    color: white;
}

.react-calendar__tile--active {
    background: #567ebb;
    color: white;
}

.react-calendar__month-view__days {
    padding: 2px;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
}

.react-calendar__month-view__weekdays__weekday {
    max-width: calc(100% / 7 - 3px);
    margin: 2px;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 71px;
}