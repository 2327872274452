
.loading-spinner {
    display: flex;
    align-items: center;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #333;
    margin: 0 5px;
    opacity: 0.3;
    transition: opacity 0.3s ease-in-out;
}

.dot.active {
    opacity: 1;
}

.overlay-container {
    position: relative;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(100, 100, 100, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}
